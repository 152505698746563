.about-section {
  padding: 2rem 0;
  width: 100%;
  background: #fafafb;
  min-height: 90vh;
  display: flex;
  align-items: center;
  h2 {
    margin-bottom: 15px;
    font-size: 32px;
  }
  h4 {
    font-size: 18px;
    font-weight: 400;
  }

  .h_line {
    width: 40px;
    height: 2px;
    margin-right: 15px;

    background: black;
  }

  .decor {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .about-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    .about-details {
      width: 40%;
      h4 {
        line-height: 25px;
      }
    }

    .image-container {
      width: 35%;
    }

    .content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .about-image {
        width: 100%;
      }
    }
  }
  @media (max-width: 1024px) {
    min-height: 50vh;
  }

  @media (max-width: 768px) {
    min-height: 90vh;

    .about-wrapper {
      flex-direction: column;

      .image-container {
        width: 50%;
      }

      .about-details {
        width: 90%;
      }
    }
  }
}

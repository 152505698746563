.gitdiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 55vh;
  padding: 0;
}

.git {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  background: #fa4c1c;
  height: 100%;
  width: 100%;
  padding-left: 30px;
  h1 {
    font-size: 46px;
    color: white;
  }
  p {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hline {
      width: 40px;
      height: 2px;
      margin-left: 15px;
      background: white;
    }
  }
}

.want-to-hire {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  background: #f96526;
  height: 100%;
  width: 100%;
  padding-left: 30px;
  h1 {
    font-size: 46px;
    color: white;
  }
}

@media (max-width: 768px) {
  .gitdiv {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .git {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      background: #fa4c1c;
      height: 100%;
      width: 100%;
      padding: 30px;
      h1 {
        font-size: 38px;
        color: white;
        margin-bottom: 20px;
      }
    }

    .want-to-hire {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      background: #f96526;
      height: 100%;
      width: 100%;
      padding-left: 30px;
      h1 {
        font-size: 38px;
        color: white;
      }
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
}

html {
  scroll-behavior: smooth;
}

img {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: white;
}

.container {
  width: 75%;
  margin: auto;
}

.footer {
  min-height: 25vh;
  background: #1a1a1f;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: white;
  }
}

.f-icon {
  color: white;
  height: 30px;
  width: 30px;
  margin: 0 15px;
  &:hover {
    color: #ff5e01;
    cursor: pointer;
  }
}

.heart-icon {
  color: rgb(255, 49, 35);
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .footer {
    min-height: 25vh;
    background: #1a1a1f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .footer-icon {
    margin-top: 30px;
  }

  .f-icon {
    color: white;
    height: 20px;
    width: 20px;
    margin: 20 15px;
    &:hover {
      color: #ff5e01;
      cursor: pointer;
    }
  }
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;

    gap: 2.5em;
  }
}

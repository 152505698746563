.hero-btn {
  margin: 40px;
  background: none;
  //   width: 150px;
  height: 50px;
  border-color: white;
  border-style: solid;
  border-radius: 5px;
  padding: 15px 20px;
  color: white;
  font-size: medium;
  font-weight: 800;
  text-align: center;
  &:hover {
    cursor: pointer;
    background: white;
    color: #ff5e01;
    border-color: white;
    transition: ease-in-out 0.15s;
  }
}

.about-btn {
  border-color: black;
  text-decoration: none;
  color: black;
  background: none;
  padding: 12px 17px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  margin-top: 40px;

  &:hover {
    background: black;
    color: white;
    cursor: pointer;
    transition: ease-in-out 0.15s;
  }
}

.get-in-touch-btn {
  background: none;
  border-color: white;
  color: white;
  padding: 15px;
  stroke: none;
  border-radius: 5px;
  border-style: solid;
  font-size: 16px;
  font-weight: 800;
  &:hover {
    cursor: pointer;
    color: black;
    background: white;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
}

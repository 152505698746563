.hero {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.heading {
  margin-top: 50px;
  color: white;
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// .get-in-touch {
//   margin: 40px;
// }

.send {
  margin-left: 10px;
}

.scroll-icon {
  width: 30px;
  height: 70px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .hero {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
  }

  .heading {
    margin-top: 50px;
    width: 300px;
    color: white;
    font-size: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .send {
    margin-left: 10px;
  }

  .scroll-icon {
    width: 30px;
    height: 70px;
    margin-bottom: 20px;
  }
}

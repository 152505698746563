.h_line2 {
  width: 40px;
  height: 2px;
  margin-left: 15px;
  background: black;
}

.skills-section {
  min-height: 90vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
  padding: 5em 0;

  // .skills-details {
  //   display: flex;
  //   justify-content: space-between;

  //   align-items: center;
  // }

  .image-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tools-img {
      width: 35%;
    }
  }

  .skills-img {
    width: 32%;
  }
}

@media (max-width: 768px) {
  .skills-section {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .image-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .tools-img {
      width: 90% !important;
    }
    .skills-img {
      width: 90% !important;
      margin-bottom: 4rem;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.tools {
  width: 80%;
}
